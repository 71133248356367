import React, { useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";

export default function StepOne({
  formType,
  setFormType,
  pieceNumber,
  setPieceNumber,
  pieceType,
  setPieceType,
  selectedCity,
  setSelectedCity,
  expireDate,
  setExpireDate,
  foreignPieceNumber,
  setForeignPieceNumber,
  foreignVille,
  setForeignVille,
  foreignValidityPeriod,
  setForeignValidityPeriod,
  handleFileChange,
  file,
}) {
  const toast = useRef(null);

  const [errors, setErrors] = useState({});

  const validateField = (fieldName, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: !value,
    }));
  };

  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  const RequiredLabel = ({ label }) => (
    <label>
      {label} <span style={{ color: "red" }}>*</span>
    </label>
  );

  return (
    <div className="step-content">
      <h2 className="text-xl mb-2">PIECE D'IDENTITE</h2>

      <RequiredLabel label="Sélectionnez le type de formulaire" />
      <Dropdown
        required
        value={formType}
        onChange={(e) => {
          setFormType(e.value);
          validateField("formType", e.value);
        }}
        options={[
          { name: "Formulaire local", code: "local" },
          {
            name: "Passport et Carte de Résident (pour les étrangers)",
            code: "foreign",
          },
        ]}
        optionLabel="name"
        placeholder="Sélectionnez le type de formulaire"
        className={`w-full mb-2 ${errors.formType ? "p-invalid" : ""}`}
      />

      {formType?.code === "local" && (
        <>
          <RequiredLabel label="Numéro de pièce" />
          <InputText
            required
            value={pieceNumber}
            onChange={(e) => {
              setPieceNumber(e.target.value);
              validateField("pieceNumber", e.target.value);
            }}
            placeholder="Votre numéro de pièce"
            className={`w-full mb-2 ${errors.pieceNumber ? "p-invalid" : ""}`}
          />

          <RequiredLabel label="Type de pièce d'identité" />
          <Dropdown
            required
            value={pieceType}
            onChange={(e) => {
              setPieceType(e.value);
              validateField("pieceType", e.value);
            }}
            options={[
              { name: "Passeport" },
              { name: "CIP" },
              { name: "Carte Biométrique" },
            ]}
            optionLabel="name"
            placeholder="Sélectionner le type de pièce d'identité"
            className={`w-full mb-2 ${errors.pieceType ? "p-invalid" : ""}`}
          />

          <RequiredLabel label="À (Ville)" />
          <InputText
            required
            value={selectedCity}
            onChange={(e) => {
              setSelectedCity(e.target.value);
              validateField("selectedCity", e.target.value);
            }}
            className={`w-full mb-2 ${errors.selectedCity ? "p-invalid" : ""}`}
            placeholder={`Ville de délivrace du ${pieceType.name?? ""}`}
          />

          <RequiredLabel label="Expire le" />
          <Calendar
            required
            value={expireDate}
            onChange={(e) => {
              setExpireDate(e.value);
              validateField("expireDate", e.value);
            }}
            dateFormat="dd/mm/yy"
            className={`w-full mb-2 ${errors.expireDate ? "p-invalid" : ""}`}
            placeholder="Choisissez la date d'expiration"
          />
        </>
      )}

      {formType?.code === "foreign" && (
        <>
          <RequiredLabel label="Numéro de la pièce" />
          <InputText
            required
            value={foreignPieceNumber}
            onChange={(e) => {
              setForeignPieceNumber(e.target.value);
              validateField("foreignPieceNumber", e.target.value);
            }}
            placeholder="Votre numéro de pièce"
            className={`w-full ${errors.foreignPieceNumber ? "p-invalid" : ""}`}
          />

          <RequiredLabel label="À (Ville)" />
          <InputText
            required
            value={foreignVille}
            onChange={(e) => {
              setForeignVille(e.target.value);
              validateField("foreignVille", e.target.value);
            }}
            className={`w-full ${errors.foreignVille ? "p-invalid" : ""}`}
            placeholder="Ville de délivrace du carte"
          />

          <RequiredLabel label="Délai de validité en mois" />
          <InputNumber
            required
            value={foreignValidityPeriod}
            onValueChange={(e) => {
              setForeignValidityPeriod(e.value);
              validateField("foreignValidityPeriod", e.target.value);
            }}
            className={`w-full ${
              errors.foreignValidityPeriod ? "p-invalid" : ""
            }`}
            placeholder="Choisissez le délai de validité"
            useGrouping={false}
          />
        </>
      )}

      <RequiredLabel label="Veuillez choisir une image selfie de votre visage avec votre pièce d'identité en main" />
      <input
        type="file"
        onChange={handleFileChange}
        className="w-full p-2 mb-2 border rounded"
      />

      {file && (
        <p className="text-sm text-gray-600">
          Fichier sélectionné: {file.name}
        </p>
      )}
    </div>
  );
}
