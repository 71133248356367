import React from "react";
import { Checkbox } from "primereact/checkbox";
export default function StepEight({
  civility,
  lastName,
  firstName,
  checked,
  setChecked,
}) {
  return (
    <div>
      <h2 className="text-xl font-bold mb-2">Politique de Confidentialité</h2>
      <p className="mb-4">
        Je déclare et garantis que les informations communiquées ci-dessus sont
        sincères correctes et exactes.
      </p>
      <p className="mb-4">
        Je m’engage, par ailleurs, à informer FEEXPAY de toute modification de
        ma situation au plus tard dans les 30 jours dudit changement (dans ce
        cas, une nouvelle fiche d’identification doit être signée par le
        déclarant).
      </p>
      <p className="mb-4">
        J'autorise FEEXPAY à retenir le montant du chargeback + 70 $ d'amende
        jusqu'à ce que je fournisse les preuves du service rendu au plaignant.
      </p>
      <p className="mb-4">
        Je reconnais que ma responsabilité uniquement sera engagée en cas de
        fraudes, blanchiment de capitaux, arnaques et escroqueries. Toutes
        déclarations fausses et erronées entrainent l’annulation du contrat
        entre {civility?.name} {lastName} {firstName} Et FEEXPAY
      </p>
      <Checkbox
        onChange={(e) => setChecked(e.checked)}
        checked={checked}
      ></Checkbox>
      <b className="ml-2">Lu et approuvé</b>
    </div>
  );
}
