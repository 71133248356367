import React from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

export default function StepSeven({
  contactName,
  setContactName,
  contactAddress,
  setContactAddress,
  contactRelation,
  setContactRelation,
  contactProfession,
  setContactProfession,
  contactWorkAddress,
  setContactWorkAddress,
  contactPhone,
  setContactPhone,
  contactEmail,
  setContactEmail,
  contactEmployer,
  setContactEmployer,
}) {
  const RequiredLabel = ({ label }) => {
    return (
      <label>
        {label} <span style={{ color: 'red' }}>*</span>
      </label>
    );
  };
  return (
    <div className="max-w-lg mx-auto p-4 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-4">
        Personne à Contacter en Cas de Nécessité
      </h1>

      <div className="mt-6">
      <RequiredLabel label="Nom et Prénoms" />
        
        <InputText
        required
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          placeholder="Nom et Prénoms"
          className="w-full mb-2 "
        />
        <RequiredLabel label="Type de Relation/Affiliation" />
        
        <InputText
        required
          value={contactRelation}
          onChange={(e) => setContactRelation(e.target.value)}
          placeholder="Type de Relation/Affiliation"
          className="w-full mb-2 "
        />
        <RequiredLabel label="Adresse Principale" />
        
        <InputText
        required
          value={contactAddress}
          onChange={(e) => setContactAddress(e.target.value)}
          placeholder="Adresse Principale"
          className="w-full mb-2 "
        />
        <RequiredLabel label="Profession" />
        
        <InputText
        required
          value={contactProfession}
          onChange={(e) => setContactProfession(e.target.value)}
          placeholder="Profession"
          className="w-full mb-2 "
        />
        <RequiredLabel label="Nom de l'Employeur" />
       
        <InputText
        required
          value={contactEmployer}
          onChange={(e) => setContactEmployer(e.target.value)}
          placeholder="Nom de l'Employeur"
          className="w-full mb-2 "
        />
        <RequiredLabel label="Adresse Professionnelle" />
        
        <InputText
        required
          value={contactWorkAddress}
          onChange={(e) => setContactWorkAddress(e.target.value)}
          placeholder="Adresse Professionnelle"
          className="w-full mb-2 "
        />
        <RequiredLabel label="Téléphone" />
       
        <InputNumber
          value={contactPhone}
          onValueChange={(e) => setContactPhone(e.value)}
          placeholder="Veuillez préciser l'indicatif du pays suivi du numéro."
          className="w-full mb-2 "
          useGrouping={false}
        />
        <RequiredLabel label="Email" />
        <InputText
        required
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          placeholder="Email"
          className="w-full mb-2 "
        />
      </div>
    </div>
  );
}
