import React from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
export function StepFour({
  mobile1,
  setMobile1,
  mobile2,
  setMobile2,
  officePhone,
  setOfficePhone,
  email1,
  setEmail1,
  email2,
  setEmail2,
}) {
  const RequiredLabel = ({ label }) => {
    return (
      <label>
        {label} <span style={{ color: 'red' }}>*</span>
      </label>
    );
  };
  return (
    <div className="step-content">
      <h2 className="text-xl mb-2">INFORMATIONS DE CONTACT</h2>
      <p className="mb-4">Veuillez entrer vos informations de contact.</p>
      
      <RequiredLabel label="Mobile 1" />
      <InputNumber
        required
        value={mobile1}
        onValueChange={(e) => setMobile1(e.value)}
        placeholder="Veuillez préciser l'indicatif du pays suivi du numéro."
        useGrouping={false}
        className="w-full mb-2 "
      />

      <label className="block mb-2">Mobile 2</label>
      <InputNumber
        value={mobile2}
        onValueChange={(e) => setMobile2(e.value)}
        placeholder="Veuillez préciser l'indicatif du pays suivi du numéro."
        useGrouping={false}
        className="w-full  mb-2 "
      />
         <label className="block mb-2">Bureau</label>
      <InputNumber
        required
        value={officePhone}
        onCValuehange={(e) => setOfficePhone(e.value)}
        useGrouping={false}
        placeholder="Téléphone de bureau"
        className="w-full  mb-2 "
      />
      
      <RequiredLabel label="Email 1" />
      <InputText
      required
        type="email"
        value={email1}
        onChange={(e) => setEmail1(e.target.value)}
        placeholder="Email 1"
        className="w-full  mb-2 "
      />

      <label className="block mb-2">Email 2</label>
      <InputText
        type="email"
        value={email2}
        onChange={(e) => setEmail2(e.target.value)}
        placeholder="Email 2"
        className="w-full  mb-2 "
      />
    </div>
  );
}
