import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React from "react";

export function StepTwo({
  civility,
  setCivility,
  lastName,
  setLastName,
  firstName,
  setFirstName,
  maidenName,
  setMaidenName,
  birthDate,
  setBirthDate,
  birthCity,
  setBirthCity,
  maritalStatus,
  setMaritalStatus,
  legalCapacity,
  setLegalCapacity,
}) {
  const RequiredLabel = ({ label }) => (
    <label>
      {label} <span style={{ color: "red" }}>*</span>
    </label>
  );

  return (
    <div className="step-content">
      <h2 className="text-xl font-semibold mb-2">ETAT CIVIL</h2>

      <RequiredLabel label="Titre de civilité" />
      <Dropdown
        required
        value={civility}
        onChange={(e) => setCivility(e.value)}
        options={[{ name: "M." }, { name: "Mme" }, { name: "Autres" }]}
        optionLabel="name"
        placeholder="Titre de civilité"
        className="w-full mb-2"
      />

      <RequiredLabel label="Nom" />
      <InputText
        required
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        className="w-full mb-2"
        placeholder="Nom"
      />

      <RequiredLabel label="Prénom" />
      <InputText
        required
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        className="w-full mb-2"
        placeholder="Prénom"
      />


      {civility?.name === "Mme" && (
        <>
          <RequiredLabel label="Nom de jeune fille" />
          <InputText
            required
            value={maidenName}
            onChange={(e) => setMaidenName(e.target.value)}
            className="w-full mb-2"
            placeholder="Nom de jeune fille"
          />
        </>
      )}

      <RequiredLabel label="Date de naissance" />
      <Calendar
        value={birthDate}
        onChange={(e) => setBirthDate(e.value)}
        dateFormat="dd/mm/yy"
        className="w-full mb-2"
        placeholder="Date de naissance"
      />

      <RequiredLabel label="Ville de naissance" />
      <InputText
        value={birthCity}
        onChange={(e) => setBirthCity(e.target.value)}
        className="w-full mb-2"
        placeholder="Ville de naissance"
        required
      />

    

      <RequiredLabel label="Statut matrimonial" />
      <Dropdown
        required
        value={maritalStatus}
        onChange={(e) => setMaritalStatus(e.value)}
        options={[
          { name: "Célibataire" },
          { name: "Marié(e)" },
          { name: "Divorcé(e)" },
          { name: "Veuf(ve)" },
        ]}
        optionLabel="name"
        placeholder="Statut matrimonial"
        className="w-full mb-2"
      />

      <RequiredLabel label="Capacité juridique" />
      <Dropdown
        required
        value={legalCapacity}
        onChange={(e) => setLegalCapacity(e.value)}
        options={[{ name: "Majeur Capable" }, { name: "Majeur Incapable" }]}
        optionLabel="name"
        placeholder="Capacité juridique"
        className="w-full mb-2"
      />
    </div>
  );
}
