import { InputText } from "primereact/inputtext";
import React from "react";

export function StepSix({
  bankName1,
  setBankName1,
  bankLocation1,
  setBankLocation1,
  accountTitle1,
  setAccountTitle1,
  accountNumber1,
  setAccountNumber1,
  accountType1,
  setAccountType1,
}) {

  const RequiredLabel = ({ label }) => {
    return (
      <label>
        {label} <span style={{ color: 'red' }}>*</span>
      </label>
    );
  };
  return (
    <div className="step-content">
      <h1 className="text-2xl font-bold mb-4">Informations Bancaires</h1>

      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4">Compte Bancaire</h3>
        <RequiredLabel label="Nom de la banque" />
        <label className="block mb-2"></label>
        <InputText
        required
          value={bankName1}
          onChange={(e) => setBankName1(e.target.value)}
          placeholder="Nom de la banque"
          className="w-full mb-2 "
        />
        
        <RequiredLabel label="Localisation/Agence" />
        <InputText
        required
          value={bankLocation1}
          onChange={(e) => setBankLocation1(e.target.value)}
          placeholder="Localisation/Agence"
          className="w-full mb-2 "
        />
        
        <RequiredLabel label="Intitulé du compte" />
        <InputText
        required
          value={accountTitle1}
          onChange={(e) => setAccountTitle1(e.target.value)}
          placeholder="Intitulé du compte"
          className="w-full mb-2 "
        />
        
        <RequiredLabel label="Type de compte" />
        <InputText
        required
          value={accountType1}
          onChange={(e) => setAccountType1(e.target.value)}
          placeholder="Type de compte"
          className="w-full mb-2 "
        />
       
        <RequiredLabel label="Numéro de compte" />
        <InputText
        required
          value={accountNumber1}
          onChange={(e) => setAccountNumber1(e.target.value)}
          placeholder="Numéro de compte"
          className="w-full mb-2 "
        />
      </div>
    </div>
  );
}
