import React from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { InputTextarea } from "primereact/inputtextarea";

export default function StepThree({
  nationality,
  setNationality,
  secondNationality,
  setSecondNationality,
  status,
  setStatus,
  residenceCountry,
  setResidenceCountry,
  residenceCity,
  setResidenceCity,
  residenceNeighborhood,
  setResidenceNeighborhood,
  lotNumber,
  setLotNumber,
  houseOwner,
  setHouseOwner,
  description,
  setDescription,
}) {
  const RequiredLabel = ({ label }) => {
    return (
      <label>
        {label} <span style={{ color: "red" }}>*</span>
      </label>
    );
  };
  return (
    <div className="step-content">
      <h2 className="text-xl mb-2">INFORMATIONS DE RÉSIDENCE</h2>
      <p className="mb-4">Veuillez entrer vos informations de résidence.</p>
      <RequiredLabel label="Nationalité d'origine" />
      <InputText
        required
        value={nationality}
        onChange={(e) => setNationality(e.target.value)}
        placeholder="Nationalité d'origine"
        className="w-full mb-2 "
      />
      <label className="block mb-2">Deuxième nationalité</label>
      <InputText
        value={secondNationality}
        onChange={(e) => setSecondNationality(e.target.value)}
        placeholder="Deuxième nationalité"
        className="w-full mb-2 "
      />
      <RequiredLabel label="Statut" />
      <Dropdown
        required
        value={status}
        onChange={(e) => setStatus(e.value)}
        options={[{ name: "Résident" }, { name: "Non-resident" }]}
        optionLabel="name"
        placeholder="Statut"
        className="w-full mb-2"
        aria-describedby="username-help"
      />
      <small id="username-help">
        Résidant dans l'un des pays où Feexpay est présent (Bénin, Togo, Côte
        d'Ivoire, Burkina Faso, Sénégal).
      </small>{" "}
      <br />
      <br />
      <RequiredLabel label="Pays de résidence" />
      <InputText
        required
        value={residenceCountry}
        onChange={(e) => setResidenceCountry(e.target.value)}
        placeholder="Pays de résidence"
        className="w-full mb-2  "
      />
      <RequiredLabel label="Ville de résidence" />
      <InputText
        required
        value={residenceCity}
        onChange={(e) => setResidenceCity(e.target.value)}
        placeholder="Ville de résidence"
        className="w-full mb-2 "
      />
      <RequiredLabel label="Quartier de résidence" />
      <InputText
        value={residenceNeighborhood}
        onChange={(e) => setResidenceNeighborhood(e.target.value)}
        placeholder="Quartier de résidence"
        className="w-full mb-2 "
      />
      <RequiredLabel label="Numéro du carré/lot" />
      <InputText
        required
        value={lotNumber}
        onChange={(e) => setLotNumber(e.target.value)}
        placeholder="Numéro du cadre/lot"
        className="w-full mb-2 "
      />
      <RequiredLabel label="Nom du propriétaire de la maison" />
      <InputText
        required
        value={houseOwner}
        onChange={(e) => setHouseOwner(e.target.value)}
        placeholder="Nom du propriétaire de la maison"
        className="w-full mb-2 "
      />
      <label className="block mb-2">Description</label>
      <InputTextarea
        autoResize
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="En cas d’absence de précision veuillez fournir une indication précise sur la situation géographique de votre résidence"
        className="w-full mb-2 "
      />
    </div>
  );
}
