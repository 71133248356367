import React from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Select from "react-select"; // Pour le select avec recherche
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";

export default function StepFive({
  contrats,
  profession,
  setProfession,
  country,
  setCountry,
  professionalAddress,
  setProfessionalAddress,
  professionalContact,
  setProfessionalContact,
  rccm,
  setRccm,
  businessCategory,
  setBusinessCategory,
  businessName,
  setBusinessName,
  registrationDate,
  setRegistrationDate,
  registrationLocation,
  setRegistrationLocation,
  businessAddress,
  setBusinessAddress,
  businessPhone,
  setBusinessPhone,
  businessEmail,
  setBusinessEmail,
  businessWebsite,
  setBusinessWebsite,
  activitySector,
  setActivitySector,
  ifu,
  setIfu,
  employeeCount,
  setEmployeeCount,
  legalRepresentative,
  setLegalRepresentative,
  employer,
  setEmployer,
  employerAddress,
  setEmployerAddress,
  employerCountry,
  setEmployerCountry,
  employerCity,
  setEmployerCity,
  employerMobile,
  setEmployerMobile,
  employerPhone,
  setEmployerPhone,
  employerEMail,
  setEmployerEMail,
  employerWeb,
  setEmployerWeb,
  employerContrat,
  setEmployerContrat,
}) {
  const RequiredLabel = ({ label }) => {
    return (
      <label>
        {label} <span style={{ color: "red" }}>*</span>
      </label>
    );
  };
  return (
    <div className="step-content">
      <h2 className="text-xl mb-2">INFORMATIONS PROFESSIONNELLES</h2>
      <p className="mb-4">Veuillez entrer vos informations professionnelles.</p>
      <RequiredLabel label="Profession" />

      <Dropdown
        value={profession}
        onChange={(e) => setProfession(e.value)}
        options={[
          { name: "Entrepreneur-Individuel" },
          { name: "Fonctionnaire-Public" },
          { name: "Fonctionnaire-Privé" },
          { name: "Ouvrier" },
       
        ]}
        optionLabel="name"
        placeholder="Profession"
        className="w-full mb-2"
      /> 
     

      <RequiredLabel label="Pays" />
      <InputText
        required
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        placeholder="Pays"
        className="w-full mb-2 "
      />

      <RequiredLabel label="Adresse professionnelle" />
      <InputText
        required
        value={professionalAddress}
        onChange={(e) => setProfessionalAddress(e.target.value)}
        placeholder="Adresse professionnelle"
        className="w-full mb-2"
      />

      <RequiredLabel label="Contact professionnel" />
      <InputNumber
        required
    
        value={professionalContact}
        onValueChange={(e) => setProfessionalContact(e.value)}
        placeholder="Veuillez préciser l'indicatif du pays suivi du numéro."
        className="w-full mb-2 "
        useGrouping={false}
      />

      {/* Affichage conditionnel du formulaire supplémentaire */}
      {profession?.name === "Entrepreneur-Individuel" && (
        <div className="mt-6 pl-4 border-l-4 border-gray-300">
          <h3 className="text-lg font-semibold mb-2">
            Informations supplémentaires pour {profession?.name.replace("-", " ")}
          </h3>

          <RequiredLabel label="RCCM" />
          <InputText
            required
            value={rccm}
            onChange={(e) => setRccm(e.target.value)}
            placeholder="RCCM"
            className="w-full  mb-2 "
          />

          <RequiredLabel label="Catégorie d'entreprise" />
          <Dropdown
            required
            value={businessCategory}
            onChange={(e) => setBusinessCategory(e.value)}
            options={[
              { name: "Micro-Entreprise" },
              { name: "Petite Entreprise" },
              { name: "Moyenne Entreprise" },
              { name: "Grande Entreprise" },
            ]}
            optionLabel="name"
            placeholder="Catégorie d'entreprise"
            className="w-full mb-2"
          />

          <RequiredLabel label="Nom commercial" />
          <InputText
            required
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            placeholder="Nom commercial"
            className="w-full mb-2"
          />

          <RequiredLabel label="Date de l'enregistrement" />
          <Calendar
            required
            value={registrationDate}
            onChange={(e) => setRegistrationDate(e.value)}
            dateFormat="dd/mm/yy"
            className="w-full mb-2"
            placeholder="Date de l'enregistrement"
          />

          <RequiredLabel label="Lieu d'enregistrement" />
          <InputText
            required
            value={registrationLocation}
            onChange={(e) => setRegistrationLocation(e.target.value)}
            placeholder="Lieu d'enregistrement"
            className="w-full mb-2 "
          />

          <RequiredLabel label="Adresse de l'entreprise" />
          <InputText
            required
            value={businessAddress}
            onChange={(e) => setBusinessAddress(e.target.value)}
            placeholder="Adresse de l'entreprise"
            className="w-full mb-2"
          />
          <RequiredLabel label="Téléphone de l'entreprise" />

          <InputNumber
            required
        
            value={businessPhone}
            onValueChange={(e) => setBusinessPhone(e.value)}
            placeholder="Veuillez préciser l'indicatif du pays suivi du numéro."
            className="w-full mb-2  "
            useGrouping={false}
          />
          <RequiredLabel label="Email de l'entreprise" />

          <InputText
            required
            type="email"
            value={businessEmail}
            onChange={(e) => setBusinessEmail(e.target.value)}
            placeholder="Email de l'entreprise"
            className="w-full mb-2 "
          />

          <label className="block mb-2">Site web de l'entreprise</label>
          <InputText
            type="url"
            value={businessWebsite}
            onChange={(e) => setBusinessWebsite(e.target.value)}
            placeholder="Site web de l'entreprise"
            className="w-full mb-2 "
          />

          <RequiredLabel label="Secteur d'activité" />
          <label className="block mb-2"></label>
          <InputText
            required
            value={activitySector}
            onChange={(e) => setActivitySector(e.target.value)}
            placeholder="Secteur d'activité"
            className="w-full mb-2 "
          />
          <RequiredLabel label=" Immatriculation fiscale et unique (IFU)" />

          <InputText
            required
            value={ifu}
            onChange={(e) => {
              const value = e.target.value;

              // Vérifier si la valeur est un nombre et si elle a 13 caractères
              if (!isNaN(value) && value.length <= 13) {
                setIfu(value); // Mettre à jour l'état uniquement si la longueur est de 13 caractères ou moins
              }
            }}
            placeholder="IFU"
            className="w-full mb-2"
          />

          <RequiredLabel label="Nombre d'employés" />
          <InputNumber
            required
            value={employeeCount}
            onValueChange={(e) => setEmployeeCount(e.value)}
            placeholder="Nombre d'employés"
            useGrouping={false}
            className="w-full mb-2"
          />
          <RequiredLabel label="Représentant légal" />

          <InputText
            required
            value={legalRepresentative}
            onChange={(e) => setLegalRepresentative(e.target.value)}
            placeholder="Représentant légal"
            className="w-full mb-2 "
          />
        </div>
      )}

      {(profession?.name=== "Fonctionnaire-Public" ||
        profession?.name === "Fonctionnaire-Privé" ||
        profession?.name === "Ouvrier") && (
          <div className="mt-6 pl-4 border-l-4 border-gray-300">
            <h3 className="text-lg font-semibold mb-2">
              Informations supplémentaires pour {profession?.name.replace("-", " ")}
            </h3>
            <RequiredLabel label="Employeur" />

            <InputText
              required
              value={employer}
              onChange={(e) => setEmployer(e.target.value)}
              placeholder="Employeur"
              className="w-full mb-2 "
            />

            <RequiredLabel label="Adresse de l'employeur" />
            <InputText
              required
              value={employerAddress}
              onChange={(e) => setEmployerAddress(e.target.value)}
              placeholder="Adresse de l'employeur"
              className="w-full mb-2 "
            />
            <RequiredLabel label="Pays" />

            <InputText
              required
              value={employerCountry}
              onChange={(e) => setEmployerCountry(e.target.value)}
              placeholder="Pays de l'employeur"
              className="w-full mb-2 "
            />

            <RequiredLabel label="Ville" />
            <InputText
              value={employerCity}
              onChange={(e) => setEmployerCity(e.target.value)}
              placeholder="Ville de l'employeur"
              className="w-full mb-2 "
            />
            <RequiredLabel label="Téléphone mobile" />

            <InputNumber
              required
              type="tel"
              value={employerMobile}
              onValueChange={(e) => setEmployerMobile(e.value)}
              useGrouping={false}
              placeholder="Veuillez saisie l'indicatif du pays suivie du numéro du téléphone "
              className="w-full mb-2 "
            />
 {/*
            <RequiredLabel label="Téléphone fixe" />
            <InputText
              type="tel"
              value={employerPhone}
              onChange={(e) => setEmployerPhone(e.target.value)}
              placeholder="Téléphone fixe"
              className="w-full mb-2 "
            /> */}
            <RequiredLabel label="Email" />

            <InputText
              required
              type="email"
              value={employerEMail}
              onChange={(e) => setEmployerEMail(e.target.value)}
              placeholder="Email"
              className="w-full mb-2 "
            />

            <label className="block mb-2">Site Web</label>
            <InputText
              type="url"
              value={employerWeb}
              onChange={(e) => setEmployerWeb(e.target.value)}
              placeholder="Site web"
              className="w-full mb-2 "
            />

            <RequiredLabel label="Contrat de Travail" />
            <Dropdown
              required
              value={employerContrat}
              onChange={(e) => setEmployerContrat(e.value)}
              options={[
                { name: "CDD" },
                { name: "CDI" },
                { name: "Occasionnel" },
                { name: "Stage" },
              ]}
              optionLabel="name"
              placeholder="Type de pièce d'identité"
              className="w-full mb-2"
            />
          </div>
        )}
    </div>
  );
}
