import React from "react";

const Nav = () => {
  return (
    <nav className=" text-white p-4 flex justify-between items-center">
      {/* Logo */}
      <div className="flex-shrink-0">
        <img
          src="./logo.png" // Remplacez ceci par le chemin vers votre logo
          alt="Logo"
          className="h-2"
          width={100}
          height={100}
        />
      </div>
    </nav>
  );
};

export default Nav;
